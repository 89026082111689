import React from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import {
  CheckoutFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { SelectField } from '../../apiForm/form/input';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';

export default function CheckConfigForm({
  defaultValues = {},
  errors = {},
  onSubmit,
  readonly,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset
        legend={t('checkoutConfig.legend.checkConfig')}
        sublegend={t('checkoutConfig.sublegend.checkConfig')}
      >
        <ArrayWrapper
          control={control}
          name="checkConfigs"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.checkConfig.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.checkConfig.remove')}
          inlineDeleteButton
          render={(index: number) => (
            <Stack
              spacing={1}
              direction="row"
              sx={{ width: '100%' }}
            >
              <SelectField
                control={control}
                name={`checkConfigs.[${index}].methodType`}
                disabled={readonly}
                label={t('checkoutConfig.label.checkConfig.methodType')}
                helperText={t('checkoutConfig.helperText.checkConfig.methodType')}
              >
                <MenuItem value="supervisor_approval">Supervisor Approval</MenuItem>
                <MenuItem value="min_age">Min Age</MenuItem>
                <MenuItem value="verify_debit_card">Verify Debit Card</MenuItem>
              </SelectField>
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
