import React from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import {
  CheckoutFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { SelectField, TextField } from '../../apiForm/form/input';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';

export default function TaxCategoriesForm({
  defaultValues = {},
  errors = {},
  onSubmit,
  readonly,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset legend={t('checkoutConfig.legend.serviceCredentials')}>
        <ArrayWrapper
          control={control}
          name="serviceCredentials"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.serviceCredential.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.serviceCredential.remove')}
          render={(index: number) => (
            <Stack spacing={2}>
              <TextField
                control={control}
                name={`serviceCredentials.[${index}].id`}
                disabled={readonly}
                label={t('checkoutConfig.label.serviceCredentials.id')}
                helperText={t('checkoutConfig.helperText.serviceCredentials.id')}
              />
              <SelectField
                control={control}
                name={`serviceCredentials.[${index}].authType`}
                disabled={readonly}
                label={t('checkoutConfig.label.serviceCredentials.authType')}
              >
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="basic">Basic Auth</MenuItem>
                <MenuItem value="bearer">Bearer Auth</MenuItem>
              </SelectField>
              <TextField
                control={control}
                name={`serviceCredentials.[${index}].uri`}
                disabled={readonly}
                label={t('checkoutConfig.label.serviceCredentials.uri')}
              />
              <TextField
                control={control}
                name={`serviceCredentials.[${index}].username`}
                disabled={readonly}
                label={t('checkoutConfig.label.serviceCredentials.username')}
              />
              <TextField
                control={control}
                name={`serviceCredentials.[${index}].secret`}
                disabled={readonly}
                label={t('checkoutConfig.label.serviceCredentials.secret')}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
