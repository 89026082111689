import React from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import {
  CheckoutFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { TextField } from '../../apiForm/form/input';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';

export default function CustomerPropertiesLookupForm({
  defaultValues = {},
  errors = {},
  onSubmit,
  readonly,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset legend={t('checkoutConfig.legend.customerPropertiesLookups')}>
        <ArrayWrapper
          control={control}
          name="customerPropertiesLookups"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.customerPropertiesLookup.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.customerPropertiesLookup.remove')}
          render={(index: number) => (
            <Stack spacing={2}>
              <TextField
                control={control}
                name={`customerPropertiesLookups.[${index}].type`}
                disabled={readonly}
                label={t('checkoutConfig.label.customerPropertiesLookups.type')}
                helperText={t('checkoutConfig.helperText.customerPropertiesLookups.type')}
              />
              <TextField
                control={control}
                name={`customerPropertiesLookups.[${index}].serviceCredentialID`}
                disabled={readonly}
                label={t('checkoutConfig.label.customerPropertiesLookups.serviceCredentialID')}
                helperText={t('checkoutConfig.helperText.customerPropertiesLookups.serviceCredentialID')}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
