import {
  defineApi,
  useProjectSpecificApiClient,
} from '../apiForm/api';
import { isSuccessfulOrCustomResponse } from '../utils/statusValidator';
import { TransactionConfig } from './transactionConfig';

const useApi = defineApi({
  getTransactionConfig: async (client) => {
    const { status, data } = await client<TransactionConfig>({
      url: '',
      method: 'GET',
      validateStatus: (httpCode) => isSuccessfulOrCustomResponse(httpCode, [404]),
    });
    if (status === 404) return {} as TransactionConfig;
    return data;
  },
  updateTransactionConfig: (client, data: TransactionConfig) => client.put('', data),
});

export default function useTransactionConfigApi() {
  const client = useProjectSpecificApiClient({ basePath: '/orders/config' });
  return useApi(client);
}
