import React, { ChangeEvent } from 'react';
import MUITextField, { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import { FieldProps } from './FieldProps';
import FieldContainer from './FieldContainer';
import useFormLabel from './useFormLabel';
import ValidationError from './ValidationError';

export interface NumberFieldProps extends FieldProps<MUITextFieldProps> {
  optional?: boolean;
}

export default function NumberField({
  control,
  name,
  rules,
  optional = false,
  ...props
}: NumberFieldProps) {
  const label = useFormLabel(name, props.label, { removeIndex: true, optionalHint: optional });

  return (
    <FieldContainer>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <MUITextField
            type="number"
            label={label}
            variant="outlined"
            value={value ?? ''}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              onChange(event.target.value === '' ? null : event.target.valueAsNumber);
            }}
            error={!!error}
            helperText={error && <ValidationError error={error} />}
            fullWidth
            {...props}
          />
        )}
        rules={rules}
      />
    </FieldContainer>
  );
}
