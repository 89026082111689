import React, { useCallback } from 'react';
import { FieldValue } from 'react-hook-form';
import DocumentTitle from '../../utilComponents/DocumentTitle';
import ResourceFormView from '../../apiForm/scaffold/ResourceFormView';
import useTransactionConfigApi from '../../project/useTransactionConfigApi';
import CleanupScheduleForm from './CleanupScheduleForm';

export default function CleanupScheduleView() {
  const api = useTransactionConfigApi();

  const fetch = useCallback(async () => {
    const config = await api.getTransactionConfig(null);
    return config;
  }, [api]);

  const onSubmit = useCallback(async (data: FieldValue<any>) => {
    await api.updateTransactionConfig(data);
  }, [api]);

  return (
    <>
      <DocumentTitle translationID="transactionConfig.cleanupSchedule.headline" />
      <div>
        <ResourceFormView
          actionName="cleanupSchedule"
          Form={CleanupScheduleForm}
          fetch={fetch}
          submit={onSubmit}
        />
      </div>
    </>
  );
}
