import React from 'react';
import { useParams } from 'react-router-dom';
import List from '@mui/material/List';
import GridViewIcon from '@mui/icons-material/GridView';
import PeopleIcon from '@mui/icons-material/People';
import KeyIcon from '@mui/icons-material/Key';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FolderIcon from '@mui/icons-material/Folder';
import ListIcon from '@mui/icons-material/List';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import DevicesIcon from '@mui/icons-material/Devices';
import AppsIcon from '@mui/icons-material/PhoneAndroid';
import OrganizationsIcon from '@mui/icons-material/Apartment';
import SidebarMenuItem from './SidebarMenuItem';
import SidebarExternalMenuItem from './SidebarExternalMenuItem';
import SidebarMenuGroup from './SidebarMenuGroup';
import { addHost } from '../utils/urls';

export default function SidebarMenu() {
  const { projectId } = useParams();

  return (
    <List>
      <SidebarMenuItem
        linkTarget="/"
        translationId="sidebarmenu.links.projects"
        icon={<GridViewIcon />}
      />
      <SidebarMenuItem
        linkTarget="/apps"
        translationId="sidebarmenu.links.apps"
        icon={<AppsIcon />}
      />
      <SidebarMenuItem
        linkTarget="/realms"
        translationId="sidebarmenu.links.realms"
        icon={<PeopleIcon />}
      />
      <SidebarMenuItem
        linkTarget="/cortex-decoder-keys"
        translationId="sidebarmenu.links.cortexDecoderKeys"
        icon={<KeyIcon />}
      />
      <SidebarMenuItem
        linkTarget="/organizations"
        translationId="sidebarmenu.links.organizations"
        icon={<OrganizationsIcon />}
      />
      {projectId && (
        <>
          <hr />
          <SidebarMenuItem
            linkTarget="project"
            translationId="sidebarmenu.links.projectConfig"
            icon={<SettingsIcon />}
          />
          <SidebarMenuGroup
            translationId="sidebarmenu.links.checkoutConfig"
            icon={<ShoppingCartIcon />}
          >
            <SidebarMenuItem
              linkTarget="checkout/general"
              translationId="sidebarmenu.links.generalConfig"
            />

            <SidebarMenuItem
              linkTarget="checkout/paymentConfiguration"
              translationId="sidebarmenu.links.paymentConfig"
            />
            <SidebarMenuItem
              linkTarget="checkout/vpos"
              translationId="sidebarmenu.links.vpos"
            />
            <SidebarMenuItem
              linkTarget="checkout/tseConfiguration"
              translationId="sidebarmenu.links.tseConfig"
            />
            <SidebarMenuItem
              linkTarget="checkout/taxCategories"
              translationId="sidebarmenu.links.taxCategories"
            />
            <SidebarMenuItem
              linkTarget="checkout/discountReasons"
              translationId="sidebarmenu.links.discountReasons"
            />
            <SidebarMenuItem
              linkTarget="checkout/fulfillmentConfig"
              translationId="sidebarmenu.links.fulfillmentConfig"
            />
            <SidebarMenuItem
              linkTarget="checkout/flows"
              translationId="sidebarmenu.links.flow"
            />
            <SidebarMenuItem
              linkTarget="checkout/controlConfig"
              translationId="sidebarmenu.links.controlConfig"
            />
            <SidebarMenuItem
              linkTarget="checkout/checkConfig"
              translationId="sidebarmenu.links.checkConfig"
            />
            <SidebarMenuItem
              linkTarget="checkout/customerPropertiesLookup"
              translationId="sidebarmenu.links.customerPropertiesLookup"
            />
            <SidebarMenuItem
              linkTarget="checkout/customerValidation"
              translationId="sidebarmenu.links.customerValidation"
            />
            <SidebarMenuItem
              linkTarget="checkout/voucherProviders"
              translationId="sidebarmenu.links.voucherProviders"
            />
            <SidebarMenuItem
              linkTarget="checkout/depositReturnVoucherProviders"
              translationId="sidebarmenu.links.depositReturnVoucherProviders"
            />
            <SidebarMenuItem
              linkTarget="checkout/serviceCredentials"
              translationId="sidebarmenu.links.serviceCredentials"
            />
            <SidebarMenuItem
              linkTarget="checkout/couponConfiguration"
              translationId="sidebarmenu.links.couponConfiguration"
            />
            <SidebarMenuItem
              linkTarget="checkout/return"
              translationId="sidebarmenu.links.return"
            />
            <SidebarMenuItem
              linkTarget="checkout/overrideTemplates"
              translationId="sidebarmenu.links.overrideTemplates"
            />
          </SidebarMenuGroup>
          <SidebarMenuGroup
            translationId="sidebarmenu.links.transactionConfig"
            icon={<FolderIcon />}
          >
            <SidebarMenuItem
              linkTarget="transaction/cleanup-schedule"
              translationId="sidebarmenu.links.cleanupRoutines"
            />
          </SidebarMenuGroup>
          <SidebarMenuItem
            linkTarget="checkout-devices"
            translationId="sidebarmenu.links.checkoutDevices"
            icon={<DevicesIcon />}
          />
          <SidebarMenuItem
            linkTarget="sidebar"
            translationId="sidebarmenu.links.sidebar"
            icon={<ListIcon />}
          />
          <SidebarExternalMenuItem
            linkTarget={addHost(window.location, `/${projectId}`, 'retailer')}
            translationId="sidebarmenu.links.retailerPortal"
            icon={<DoubleArrowIcon />}
          />
        </>
      )}
    </List>
  );
}
