import React from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import {
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { CheckoutFormProps } from '../../apiForm/form/CustomFormProps';
import { NumberField, SelectField } from '../../apiForm/form/input';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';

export default function ControlConfigForm({
  defaultValues = {},
  errors = {},
  onSubmit,
  readonly,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset
        legend={t('checkoutConfig.legend.controlConfig')}
        sublegend={t('checkoutConfig.sublegend.controlConfig')}
      >
        <NumberField
          control={control}
          name="controlConfig.indicationProbability"
          disabled={readonly}
          label={t('checkoutConfig.label.controlConfig.indicationProbability')}
          helperText={t('checkoutConfig.helperText.controlConfig.indicationProbability')}
          rules={{
            min: 0,
            max: 1,
          }}
        />
      </ExpandableFieldset>

      <ExpandableFieldset
        legend={t('checkoutConfig.legend.controlConfigIndicationRules')}
      >
        <ArrayWrapper
          control={control}
          name="controlConfig.indicationRules"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.indicationRule.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.indicationRule.remove')}
          render={(index: number) => (
            <Stack spacing={1}>
              <SelectField
                control={control}
                name={`controlConfig.indicationRules.[${index}].type`}
                disabled={readonly}
                label={t('checkoutConfig.label.controlConfig.indicationRuleType')}
              >
                <MenuItem value="checkoutAttribute">Checkout Attribute</MenuItem>
                <MenuItem value="paymentOriginFirstUse">Payment Origin First Use</MenuItem>
                <MenuItem value="productAttribute">Product Attribute</MenuItem>
              </SelectField>
              <NumberField
                control={control}
                name={`controlConfig.indicationRules.[${index}].weight`}
                rules={{
                  pattern: /^[0-9]+$/,
                  min: 0,
                }}
                disabled={readonly}
                label={t('checkoutConfig.label.controlConfig.weight')}
                helperText={t('checkoutConfig.helperText.controlConfig.weight')}
              />
              <NumberField
                control={control}
                name={`controlConfig.indicationRules.[${index}].config.totalPrice`}
                rules={{
                  pattern: /^[0-9]+$/,
                  min: 0,
                }}
                optional
                disabled={readonly}
                label={t('checkoutConfig.label.controlConfig.totalPrice')}
              />
              <NumberField
                control={control}
                name={`controlConfig.indicationRules.[${index}].config.numberOfProducts`}
                rules={{
                  pattern: /^[0-9]+$/,
                  min: 0,
                }}
                optional
                disabled={readonly}
                label={t('checkoutConfig.label.controlConfig.numberOfProducts')}
              />
              <NumberField
                control={control}
                name={`controlConfig.indicationRules.[${index}].config.numberOfSingleProduct`}
                rules={{
                  pattern: /^[0-9]+$/,
                  min: 0,
                }}
                optional
                disabled={readonly}
                label={t('checkoutConfig.label.controlConfig.numberOfSingleProduct')}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <ExpandableFieldset
        legend={t('checkoutConfig.legend.controlConfigProbabilityRules')}
      >
        <ArrayWrapper
          control={control}
          name="controlConfig.probabilityRules"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.probabilityRule.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.probabilityRule.remove')}
          inlineDeleteButton
          render={(index: number) => (
            <Stack
              spacing={1}
              direction="row"
              sx={{ width: '100%' }}
            >
              <SelectField
                control={control}
                name={`controlConfig.probabilityRules.[${index}].type`}
                disabled={readonly}
                label={t('checkoutConfig.label.controlConfig.probabilityRuleType')}
              >
                <MenuItem value="historyBasedWithProductAttributes">History Based With Product Attributes</MenuItem>
              </SelectField>
              <NumberField
                control={control}
                name={`controlConfig.probabilityRules.[${index}].standardControlRatio`}
                rules={{
                  min: 0,
                  max: 1,
                }}
                disabled={readonly}
                label={t('checkoutConfig.label.controlConfig.standardControlRatio')}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
