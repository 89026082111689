import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { FieldValues } from 'react-hook-form';
import {
  CustomFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { NumberField, SelectField, TextField } from '../../apiForm/form/input';
import useResourceTranslator from '../../apiForm/resource/useResourceTranslator';
import CheckboxField from '../../apiForm/form/input/CheckboxField';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';

const DEFAULT_VALUES: any = {
};

interface CheckoutFormProps<TFieldValues extends FieldValues = FieldValues>
  extends CustomFormProps<TFieldValues> {
  readonly?: boolean;
}

export default function CheckoutForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  readonly,
}: CheckoutFormProps<any>) {
  const t = useResourceTranslator();

  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset category="general">
        <TextField
          control={control}
          name="id"
          disabled
          label={t('checkoutConfig.label.general.id')}
        />

        <SelectField
          control={control}
          name="weightRoundingMode"
          disabled={readonly}
          label={t('checkoutConfig.label.general.weightRoundingMode')}
        >
          <MenuItem value="up">Up</MenuItem>
          <MenuItem value="down">Down</MenuItem>
          <MenuItem value="commercial">Commercial</MenuItem>
        </SelectField>

        <CheckboxField
          control={control}
          name="trustClientPrice"
          disabled={readonly}
          label={`${t('checkoutConfig.label.general.trustClientPrice')} (${t('checkoutConfig.helperText.general.trustClientPrice')})`}
        />

        <CheckboxField
          control={control}
          name="trustFallbackPrice"
          disabled={readonly}
          label={`${t('checkoutConfig.label.general.trustFallbackPrice')} (${t('checkoutConfig.helperText.general.trustFallbackPrice')})`}
        />

        <TextField
          control={control}
          name="currency"
          disabled={readonly}
          label={t('checkoutConfig.label.general.currency')}
          helperText={t('checkoutConfig.helperText.general.currency')}
        />

        <TextField
          control={control}
          name="locale"
          disabled={readonly}
          label={t('checkoutConfig.label.general.locale')}
          helperText={t('checkoutConfig.helperText.general.locale')}
        />

        <SelectField
          control={control}
          name="totalRoundingMode"
          disabled={readonly}
          label={t('checkoutConfig.label.general.totalRoundingMode')}
          helperText={t('checkoutConfig.helperText.general.totalRoundingMode')}
        >
          <MenuItem value="none">None</MenuItem>
          <MenuItem value="rappenRounding">Rappen Rounding</MenuItem>
        </SelectField>

        <CheckboxField
          control={control}
          name="throttleProcessCreation"
          disabled={readonly}
          label={`${t('checkoutConfig.label.general.throttleProcessCreation')} (${t('checkoutConfig.helperText.general.throttleProcessCreation')})`}
        />
        <CheckboxField
          control={control}
          name="useDeviceSpecificSequenceNumbers"
          disabled={readonly}
          label={t('checkoutConfig.label.general.useDeviceSpecificSequenceNumbers')}
        />

        <TextField
          control={control}
          name="sequenceNumberResetPeriod"
          disabled={readonly}
          label={t('checkoutConfig.label.general.sequenceNumberResetPeriod')}
        />

        <CheckboxField
          control={control}
          name="generateReceiptForAllCheckouts"
          disabled={readonly}
          label={`${t('checkoutConfig.label.general.generateReceiptForAllCheckouts')} (${t('checkoutConfig.helperText.general.generateReceiptForAllCheckouts')})`}
        />

        <CheckboxField
          control={control}
          name="generateReceiptNumber"
          disabled={readonly}
          label={t('checkoutConfig.label.general.generateReceiptNumber')}
        />

        <SelectField
          control={control}
          name="receiptNumberResetPeriod"
          disabled={readonly}
          label={t('checkoutConfig.label.general.receiptNumberResetPeriod')}
        >
          <MenuItem value="daily">Daily</MenuItem>
        </SelectField>

        <NumberField
          control={control}
          name="receiptNumberOffset"
          disabled={readonly}
          label={t('checkoutConfig.label.general.receiptNumberOffset')}
        />

        <NumberField
          control={control}
          name="maximumManualDiscountInPercent"
          disabled={readonly}
          label={t('checkoutConfig.label.general.maximumManualDiscountInPercent')}
        />
      </ExpandableFieldset>

      <ExpandableFieldset category="loyaltyProgram">
        <SelectField
          control={control}
          name="loyaltyProgram"
          disabled={readonly}
          label={t('checkoutConfig.label.loyaltyProgram.loyaltyProgram')}
          helperText={t('checkoutConfig.helperText.loyaltyProgram.loyaltyProgram')}
        >
          <MenuItem value={null}>None</MenuItem>
          <MenuItem value="payback">Payback</MenuItem>
        </SelectField>
      </ExpandableFieldset>

      <ExpandableFieldset category="messages">
        <TextField
          control={control}
          name="messages.manualDiscountCart"
          disabled={readonly}
          label={t('checkoutConfig.label.messages.manualDiscountCart')}
          helperText={t('checkoutConfig.helperText.messages.manualDiscountCart')}
        />
        <TextField
          control={control}
          name="messages.manualDiscountLineItem"
          disabled={readonly}
          label={t('checkoutConfig.label.messages.manualDiscountLineItem')}
          helperText={t('checkoutConfig.helperText.messages.manualDiscountLineItem')}
        />
      </ExpandableFieldset>

      <ExpandableFieldset category="features">
        <TextField
          control={control}
          name="features"
          disabled={readonly}
          label={t('checkoutConfig.label.general.features')}
          helperText={t('checkoutConfig.helperText.general.features')}
        />
        <a
          href="https://github.com/snabble/checkout/blob/master/core/config/feature/flags.go"
          target="_blank"
          rel="noreferrer"
        >
          {t('links.checkoutFeatures')}
        </a>
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
