import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckoutFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { TextField } from '../../apiForm/form/input';
import CheckboxField from '../../apiForm/form/input/CheckboxField';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';

export default function CouponConfigurationForm({
  defaultValues = {},
  errors = {},
  onSubmit,
  readonly,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset legend={t('checkoutConfig.legend.promotionConfiguration')}>
        <CheckboxField
          control={control}
          name="usePromotions"
          disabled={readonly}
          label={`${t('checkoutConfig.label.general.usePromotions')} (${t('checkoutConfig.helperText.general.usePromotions')})`}
        />
      </ExpandableFieldset>

      <ExpandableFieldset legend={t('checkoutConfig.legend.couponConfiguration')}>
        <TextField
          control={control}
          name="couponConfiguration.serviceCredentialID"
          disabled={readonly}
          optional
          label={t('checkoutConfig.label.couponConfiguration.serviceCredentialID')}
          helperText={t('checkoutConfig.helperText.couponConfiguration.serviceCredentialID')}
        />

        <TextField
          control={control}
          name="couponConfiguration.customRedemptionType"
          disabled={readonly}
          optional
          label={t('checkoutConfig.label.couponConfiguration.customRedemptionType')}
          helperText={t('checkoutConfig.helperText.couponConfiguration.customRedemptionType')}
        />

        <CheckboxField
          control={control}
          name="couponConfiguration.useCouponsService"
          disabled={readonly}
          label={`${t('checkoutConfig.label.couponConfiguration.useCouponsService')} (${t('checkoutConfig.helperText.couponConfiguration.useCouponsService')})`}
        />

        <TextField
          control={control}
          name="couponConfiguration.couponLookupURL"
          disabled={readonly}
          optional
          label={t('checkoutConfig.label.couponConfiguration.couponLookupURL')}
          helperText={t('checkoutConfig.helperText.couponConfiguration.couponLookupURL')}
        />
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
