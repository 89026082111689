import React from 'react';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import CleanupScheduleView from './CleanupScheduleView';

// TODO once available update the / navigation to point to the general config view

export default function TransactionRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="cleanup-schedule" replace />} />
      <Route path="/cleanup-schedule" element={<CleanupScheduleView />} />
    </Routes>
  );
}
