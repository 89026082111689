import React from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import {
  CheckoutFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { TextField } from '../../apiForm/form/input';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';

export default function TaxCategoriesForm({
  defaultValues = {},
  errors = {},
  onSubmit,
  readonly,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset legend={t('checkoutConfig.legend.taxCategories')}>
        <ArrayWrapper
          control={control}
          name="taxCategories"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.taxCategory.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.taxCategory.remove')}
          render={(index: number) => (
            <Stack spacing={1}>
              <TextField
                control={control}
                name={`taxCategories.[${index}].id`}
                disabled={readonly}
                label={t('checkoutConfig.label.tax.id')}
              />
              <ArrayWrapper
                control={control}
                name={`taxCategories.[${index}].validRates`}
                defaultValue={{}}
                addLabel={t('checkoutConfig.arrayWrapper.validRate.add')}
                removeLabel={t('checkoutConfig.arrayWrapper.validRate.remove')}
                indent
                inlineDeleteButton
                render={(index2: number) => (
                  <Stack spacing={1} direction="row">
                    <TextField
                      control={control}
                      name={`taxCategories.[${index}].validRates.[${index2}].rate`}
                      disabled={readonly}
                      label={t('checkoutConfig.label.tax.rate')}
                    />
                    <TextField
                      control={control}
                      name={`taxCategories.[${index}].validRates.[${index2}].validFrom`}
                      disabled={readonly}
                      label={t('checkoutConfig.label.tax.validFrom')}
                      helperText={t('checkoutConfig.helperText.tax.validFrom')}
                    />
                    <TextField
                      control={control}
                      name={`taxCategories.[${index}].validRates.[${index2}].validUntil`}
                      disabled={readonly}
                      label={t('checkoutConfig.label.tax.validUntil')}
                      helperText={t('checkoutConfig.helperText.tax.validUntil')}
                    />
                  </Stack>
                )}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
