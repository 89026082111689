import React from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import {
  CheckoutFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { SelectField, TextField } from '../../apiForm/form/input';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import CheckboxField from '../../apiForm/form/input/CheckboxField';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';
import { FiscalizationProviderKind } from '../../project/CheckoutConfig';

export default function TseConfigurationForm({
  defaultValues = {},
  errors = {},
  onSubmit,
  readonly,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
    watch,
  } = useEnhancedForm({ defaultValues, errors });

  const fiscalizationProviderKind = watch('fiscalizationProvider.kind') as FiscalizationProviderKind;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset legend={t('checkoutConfig.legend.fiscalizationProvider')}>
        <SelectField
          control={control}
          name="fiscalizationProvider.kind"
          disabled={readonly}
          label={t('checkoutConfig.label.tseConfiguration.providerKind')}
        >
          <MenuItem value=""><em>None</em></MenuItem>
          <MenuItem value="efsta_at">EFSTA (AT)</MenuItem>
          <MenuItem value="efsta_de">EFSTA (DE)</MenuItem>
          <MenuItem value="deutscheFiskal">Deutsche Fiskal</MenuItem>
        </SelectField>
        <TextField
          control={control}
          name="fiscalizationProvider.baseURI"
          disabled={readonly}
          label={t('checkoutConfig.label.tseConfiguration.baseURI')}
          rules={{
            validate: (value) => !value || fiscalizationProviderKind === 'efsta_de' || fiscalizationProviderKind === 'efsta_at',
          }}
          helperText={t('checkoutConfig.helperText.tseConfiguration.fiscalizationBaseUri')}
        />
        <TextField
          control={control}
          name="fiscalizationProvider.closingTime"
          disabled={readonly}
          label={t('checkoutConfig.label.tseConfiguration.closingTime')}
          rules={{
            validate: (value) => !value || fiscalizationProviderKind === 'efsta_de',
          }}
          helperText={t('checkoutConfig.helperText.tseConfiguration.closingTime')}
        />
      </ExpandableFieldset>

      <ExpandableFieldset legend={t('checkoutConfig.legend.tseConfiguration')}>
        <ArrayWrapper
          control={control}
          name="tseConfiguration"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.tseConfig.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.tseConfig.remove')}
          render={(index: number) => (
            <Stack spacing={1}>
              <TextField
                control={control}
                name={`tseConfiguration.[${index}].shopID`}
                disabled={readonly}
                label={t('checkoutConfig.label.tseConfiguration.shopID')}
              />
              <CheckboxField
                control={control}
                name={`tseConfiguration.[${index}].disabled`}
                disabled={readonly}
                label={t('checkoutConfig.label.tseConfiguration.disabled')}
              />
              <TextField
                control={control}
                name={`tseConfiguration.[${index}].baseURI`}
                disabled={readonly}
                label={t('checkoutConfig.label.tseConfiguration.baseURI')}
                rules={{
                  validate: (value) => !value || fiscalizationProviderKind === 'deutscheFiskal',
                }}
                helperText={t('checkoutConfig.helperText.tseConfiguration.tseConfigurationBaseUri')}
              />
              <Stack direction="row" spacing={2} flexGrow={1} sx={{ '> div': { flexGrow: 1 } }}>
                <TextField
                  control={control}
                  name={`tseConfiguration.[${index}].ersIDOverwrite`}
                  disabled={readonly}
                  label={t('checkoutConfig.label.tseConfiguration.ersIDOverwrite')}
                  helperText={t('checkoutConfig.helperText.tseConfiguration.ersIDOverwrite')}
                  rules={{ pattern: /^[A-Za-z0-9 ()'+,-.=]{1,32}$/ }}
                />
                <TextField
                  control={control}
                  name={`tseConfiguration.[${index}].generatedErsID`}
                  disabled
                  label={t('checkoutConfig.label.tseConfiguration.generatedErsID')}
                  helperText={t('checkoutConfig.helperText.tseConfiguration.generatedErsID')}

                />
              </Stack>
              <TextField
                control={control}
                name={`tseConfiguration.[${index}].ersSecret`}
                disabled={readonly}
                label={t('checkoutConfig.label.tseConfiguration.ersSecret')}
              />
              <SelectField
                control={control}
                name={`tseConfiguration.[${index}].type`}
                disabled={readonly}
                label={t('checkoutConfig.label.tseConfiguration.type')}
              >
                <MenuItem value="local">Local</MenuItem>
                <MenuItem value="fcc">FCC</MenuItem>
              </SelectField>
              <TextField
                control={control}
                name={`tseConfiguration.[${index}].defaultTerminalID`}
                disabled={readonly}
                label={t('checkoutConfig.label.tseConfiguration.defaultTerminalID')}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
