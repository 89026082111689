import React from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import {
  CheckoutFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import { TextField } from '../../apiForm/form/input';
import ArrayWrapper from '../../apiForm/wrapper/ArrayWrapper';
import CheckboxField from '../../apiForm/form/input/CheckboxField';
import ExpandableFieldset from '../../apiForm/form/ExpandableFieldset';

export default function CustomerValidationForm({
  defaultValues = {},
  errors = {},
  onSubmit,
  readonly,
}: CheckoutFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ExpandableFieldset legend={t('checkoutConfig.legend.customerValidations')}>
        <ArrayWrapper
          control={control}
          name="customerValidations"
          defaultValue={{}}
          addLabel={t('checkoutConfig.arrayWrapper.customerValidation.add')}
          removeLabel={t('checkoutConfig.arrayWrapper.customerValidation.remove')}
          render={(index: number) => (
            <Stack spacing={1}>
              <TextField
                control={control}
                name={`customerValidations.[${index}].type`}
                disabled={readonly}
                label={t('checkoutConfig.label.customerValidations.type')}
              />
              <TextField
                control={control}
                name={`customerValidations.[${index}].validationExpression`}
                optional
                disabled={readonly}
                label={t('checkoutConfig.label.customerValidations.validationExpression')}
              />
              <CheckboxField
                control={control}
                name={`customerValidations.[${index}].required`}
                disabled={readonly}
                label={t('checkoutConfig.label.customerValidations.required')}
              />
            </Stack>
          )}
        />
      </ExpandableFieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
