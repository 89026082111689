import { SortableTableDataPoint } from '../DataDisplay/SortableTable';

export interface FlowConfiguration {
  shopID?: string;
  supervisorApprovalRequired: boolean;
  supervisorApprovalMethod: 'none' | 'control' | 'partialRescan' | 'rescan' | 'gatekeeeper';
  supervisorApprovalMethodYellow: 'none' | 'control' | 'partialRescan' | 'rescan' | 'gatekeeeper';
  supervisorApprovalMethodRed: 'none' | 'control' | 'partialRescan' | 'rescan' | 'gatekeeeper';
  ageVerificationMode: 'none' | 'inPaymentProcess' | 'supervisor';
  ageVerificationIgnoreStoredAge?: boolean;
  exitTokenProvider: string;
  exitTokenConfiguration: null | {
    secret: string;
  }
}

interface ValidationPolicy {
  type: string;
  length?: number;
}

export enum PaymentMethodID {
  APPLE_PAY = 'applePay',
  AUTONOMO_ONLINE_PAYMENT = 'autonomoOnlinePayment',
  AUTONOMO_TERMINAL = 'autonomoTerminal',
  CASH = 'cash',
  CREDIT_CARD_AMERICAN_EXPRESS = 'creditCardAmericanExpress',
  CREDIT_CARD_MASTERCARD = 'creditCardMastercard',
  CREDIT_CARD_VISA = 'creditCardVisa',
  CUSTOMER_CARD_POS = 'customerCardPOS',
  DE_DIRECT_DEBIT = 'deDirectDebit',
  ENCODED_CODES = 'encodedCodes',
  ENCODED_CODES_CSV = 'encodedCodesCSV',
  EXTERNAL_BILLING = 'externalBilling',
  GATEKEEPER_EXTERNAL_BILLING = 'gatekeeperExternalBilling',
  GATEKEEPER_TERMINAL = 'gatekeeperTerminal',
  GOOGLE_PAY = 'googlePay',
  POST_FINANCE_CARD = 'postFinanceCard',
  QR_CODE_OFFLINE = 'qrCodeOffline',
  QR_CODE_POS = 'qrCodePOS',
  SMOOTHR_TERMINAL = 'smoothrTerminal',
  TEST_SEPA = 'testSepa',
  TWINT = 'twint',
  VOUCHER = 'voucher',
}

interface PaymentConfiguration {
  methodID: PaymentMethodID;
  methodName: string;
  baseURI: string;
  originVerificationRequired: boolean;
  limit?: number;
  acceptedOriginTypes: string[];
  providerName: string;
  triggerReceiptGeneration: boolean;
  verifiesAge: boolean;
  manageSepaMandateByProvider: boolean;
  isTesting: boolean;
  customerIDValidationPolicy?: ValidationPolicy;
  passwordValidationPolicy?: ValidationPolicy;
  allowRefunds?: boolean;
}

interface ShopPaymentConfiguration {
  shopID: string;
  configurations?: PaymentConfiguration[];
}

interface VPoSConfig {
  completionURL: string;
  placementURL: string;
  username?: string;
  password?: string;
  token?: string;
  timeout?: number | string; // unsure
  fallbackToLocalCompletion: boolean;
  logRequests: boolean;
  clientCert?: string;
  clientKey?: string;
}

interface ShopVPoSConfig extends VPoSConfig {
  shopID: string;
}

interface ProjectVPoSConfig extends VPoSConfig {
  shopConfigs?: ShopVPoSConfig[];
}

export type FiscalizationProviderKind = 'efsta_at' | 'efsta_de' | 'deutscheFiskal';

interface FiscalizationProvider {
  kind: FiscalizationProviderKind;
  baseURI: string;
  closingTime: string;
}

interface VoucherProvider {
  id: string;
  name: string;
  serviceCredentialID: string;
  companyID: string;
  multipleRedemptionsAllowed: boolean;
  minTotalThreshold: number;
  templates: string[];
}

interface Mapping {
  key: string;
  value: string;
}

interface DepositReturnVoucherProvider {
  id: string;
  name: string;
  token: string;
  templates: string[];
  mappings: Mapping[];
}

interface ServiceCredential {
  id: string;
  authType: string;
  uri: string;
  username: string;
  secret: string;
}

interface CouponConfiguration {
  serviceCredentialID: string;
  customRedemptionType: string;
  useCouponsService: boolean;
  couponLookupURL: string;
}

interface OverrideTemplate {
  id: string,
  template: string;
  transmissionTemplate: string
  transmissionCode: string
}

export interface CheckoutConfig extends SortableTableDataPoint {
  id: string;
  defaultFlow: FlowConfiguration; // Object
  flows?: FlowConfiguration[]; // Array of Objects
  // Array of Objects with String Array // !!! maybe with comma separated strings
  defaultPaymentConfigurations?: PaymentConfiguration[];
  // Array of Objects with Array of Objects with String Array // !!!
  paymentConfigurations?: ShopPaymentConfiguration[];
  vpos: ProjectVPoSConfig; // Object with Array of Objects
  usePromotions: boolean;
  useCouponsService: boolean;
  couponLookupURL?: string;
  couponConfiguration: CouponConfiguration;
  weightRoundingMode: 'up' | 'down' | 'commercial';
  trustClientPrice: boolean;
  trustFallbackPrice: boolean;
  currency: string;
  totalRoundingMode: 'none' | 'rappenRounding';
  throttleProcessCreation: boolean;
  taxCategories: Array<{ // Array with Object with Array of Objects // !!!
    id: string;
    validRates: Array<{
      rate: string; // unsure
      validFrom: null | string; // unsure
      validUntil: null | string; // unsure
    }>;
  }>;
  checkConfigs: Array<{ // Object
    methodType: string;
  }>;
  // Array of Objects with Array of Strings // !!! maybe with comma separated strings
  fulfillmentConfigs: Array<{
    fulfillmentType: string;
    createURL: string;
    shops?: string[];
    addPaymentReference: boolean;
  }>;
  customerPropertiesLookups: Array<{ // Array of Objects
    type: string;
    serviceCredentialID?: string;
  }>;
  customerValidations: Array<{ // Array of Objects
    type: string;
    validationExpression?: string;
    required?: boolean;
  }>;
  maximumManualDiscountInPercent: number | string; // unsure
  discountReasons: Array<{ // Array of Objects
    method: 'priceOverwrite' | 'basePriceOverwrite' | 'priceEntry' | 'manualCartDiscount' | 'manualLineItemDiscount' | 'promotion';
    code: string;
    message?: string;
  }>;
  messages: {
    manualDiscountCart: string;
    manualDiscountLineItem: string;
  };
  useDeviceSpecificSequenceNumbers: boolean;
  sequenceNumberResetPeriod: string;
  fiscalizationProvider: FiscalizationProvider;
  tseConfiguration: Array<{ // Array of Objects
    providerKind: FiscalizationProviderKind;
    baseURI: string;
    disabled?: boolean;
    shopID: string;
    ersIDOverwrite: string;
    generatedErsID: string;
    ersSecret: string;
    type: string;
    defaultTerminalID: string;
  }>;
  generateReceiptForAllCheckouts: boolean;
  generateReceiptNumber: boolean;
  receiptNumberResetPeriod: string;
  receiptNumberOffset: number;
  controlConfig: { // Object with Array of Objects
    indicationProbability: number;
    indicationRules: Array<{
      type: string;
      weight: number;
      config: {
        totalPrice?: number;
        numberOfProducts?: number;
        numberOfSingleProduct?: number;
      };
    }>;
    probabilityRules: Array<{ // Array of Objects
      type: string;
      standardControlRatio: number;
    }>;
  };
  voucherProviders: VoucherProvider[];
  depositReturnVoucherProviders: DepositReturnVoucherProvider[];
  serviceCredentials: ServiceCredential[];
  features: string[];
  allowReturns: boolean,
  returnReasons: Array<{ // Array of Objects
    id: string;
    code: string;
    name: string;
  }>;
  overrideTemplates: Array<OverrideTemplate>;
}
