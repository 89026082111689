import React from 'react';
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import CheckoutDevicesView from '../pages/CheckoutDevicesView/CheckoutDevicesView';
import CheckoutRoutes from '../pages/CheckoutView/CheckoutRoutes';
import ProjectView from '../pages/ProjectView/ProjectView';
import SidebarView from '../pages/SidebarView/SidebarView';
import TransactionRoutes from '../pages/TransactionView/TransactionRoutes';

export default function ProjectSpecificRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="project" replace />} />
      <Route path="/project" element={<ProjectView />} />
      <Route path="/checkout/*" element={<CheckoutRoutes />} />
      <Route path="/transaction/*" element={<TransactionRoutes />} />
      <Route path="/checkout-devices" element={<CheckoutDevicesView />} />
      <Route path="/sidebar" element={<SidebarView />} />
    </Routes>
  );
}
