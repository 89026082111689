import React from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../../apiForm/form';
import CheckboxField from '../../apiForm/form/input/CheckboxField';
import { NumberField } from '../../apiForm/form/input';

const DEFAULT_VALUES: any = {};

interface CleanupScheduleFormProps<TFieldValues extends FieldValues = FieldValues>
  extends CustomFormProps<TFieldValues> {
}

export default function CleanupScheduleForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}: CleanupScheduleFormProps<any>) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState,
    watch,
  } = useEnhancedForm({ defaultValues, errors });

  const isScheduleEnabled = watch('cleanupSchedule.enabled');

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset
        category="cleanupSchedules"
        legend={t('transactionConfig.cleanupSchedule.fieldset.general')}
      >
        <div style={{ fontWeight: 'bold', color: 'red' }}>
          {t('transactionConfig.cleanupSchedule.helperText.generalWarning')}
        </div>
        <CheckboxField
          name="cleanupSchedule.enabled"
          control={control}
          label={t('transactionConfig.cleanupSchedule.label.enabled')}
        />
        <NumberField
          name="cleanupSchedule.staleDays"
          control={control}
          label={t('transactionConfig.cleanupSchedule.label.staleDays')}
          helperText={t('transactionConfig.cleanupSchedule.helperText.staleDays')}
          rules={{
            min: 30,
            validate: {
              requiredIfEnabled: (value) => {
                if (isScheduleEnabled && !value) return 'Must be set if schedule is enabled';
                return true;
              },
            },
          }}
        />
      </Fieldset>

      <DefaultFormActions
        formState={formState}
        cancelNavigationTarget="/"
      />
    </Form>
  );
}
